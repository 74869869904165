import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74')
];

export const server_loads = [2,4,7];

export const dictionary = {
		"/(app)": [10,[2],[3]],
		"/(app)/accelerator/companies": [12,[2,4],[3]],
		"/(app)/accelerator/companies/edit/[slug]": [~14,[2,4],[3]],
		"/(app)/accelerator/companies/new": [~15,[2,4],[3]],
		"/(app)/accelerator/companies/[slug]": [13,[2,4,5],[3]],
		"/(app)/accelerator/founders": [16,[2,4],[3]],
		"/(app)/accelerator/investors": [17,[2,4],[3]],
		"/(app)/accelerator/investors/edit/[slug]": [~20,[2,4],[3]],
		"/(app)/accelerator/investors/new": [~21,[2,4],[3]],
		"/(app)/accelerator/investors/review": [22,[2,4],[3]],
		"/(app)/accelerator/investors/review/[slug]": [~23,[2,4],[3]],
		"/(app)/accelerator/investors/review/[slug]/edit/[id]": [~24,[2,4],[3]],
		"/(app)/accelerator/investors/[slug]": [18,[2,4,6],[3]],
		"/(app)/accelerator/investors/[slug]/reviews": [19,[2,4,6],[3]],
		"/(app)/accelerator/mentor-referrals": [25,[2,4],[3]],
		"/(app)/accelerator/onboarding": [~26,[2,4],[3]],
		"/analytics": [72],
		"/(app)/(admin)/announcements": [11,[2],[3]],
		"/(app)/companies/[...rest]": [27,[2],[3]],
		"/components": [73],
		"/(auth)/email-confirmation": [57],
		"/(auth)/email-confirmation/failed": [58],
		"/(auth)/email-confirmation/request-verification": [59],
		"/(auth)/email-confirmed": [60],
		"/(app)/forum/[id=forum]": [~28,[2],[3]],
		"/(app)/founders": [29,[2],[3]],
		"/(app)/hackathon": [~30,[2],[3]],
		"/(app)/hackathon/dev": [~31,[2],[3]],
		"/(app)/investors/[...rest]": [32,[2],[3]],
		"/login": [74],
		"/(app)/messages": [33,[2],[3]],
		"/(auth)/oauth-callback/github": [61],
		"/(auth)/oauth-callback/google": [62],
		"/(app)/onboarding": [~34,[2],[3]],
		"/(app)/posts": [~35,[2],[3]],
		"/(app)/posts/[id]": [~36,[2,7],[3]],
		"/(app)/posts/[id]/comments/[commentId]": [37,[2,7],[3]],
		"/(app)/profiles": [38,[2],[3]],
		"/(app)/profiles/[username]": [39,[2,8],[3]],
		"/(app)/profiles/[username]/activity": [~40,[2,8,9],[3]],
		"/(app)/profiles/[username]/activity/comments": [41,[2,8,9],[3]],
		"/(app)/projects": [~42,[2],[3]],
		"/(app)/projects/editor": [~43,[2],[3]],
		"/(app)/projects/ended": [44,[2],[3]],
		"/(app)/projects/explore": [45,[2],[3]],
		"/(app)/projects/explore/[slug]": [46,[2],[3]],
		"/(app)/projects/hackathon/[slug]": [~47,[2],[3]],
		"/(app)/projects/hall-of-fame": [48,[2],[3]],
		"/(app)/projects/hall-of-fame/[projectId]": [49,[2],[3]],
		"/(app)/projects/submit": [~50,[2],[3]],
		"/(app)/projects/success": [51,[2],[3]],
		"/(app)/referrals": [52,[2],[3]],
		"/(auth)/refresh-session": [63],
		"/(app)/register": [~53,[2],[3]],
		"/(app)/reports": [54,[2],[3]],
		"/(auth)/reset-password": [64],
		"/(auth)/reset-password/link-expired": [65],
		"/(auth)/reset-password/link-sent": [66],
		"/(auth)/reset-password/new": [67],
		"/(auth)/reset-password/success": [68],
		"/(app)/settings": [55,[2],[3]],
		"/(auth)/signin": [69],
		"/(auth)/signin/verify-magic-link": [70],
		"/(auth)/signup": [71],
		"/(app)/support": [56,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';